<template>
  <header class="header">
    <div :class="{'sticky': scrolled}" class="site-header">
      <router-link to="/" class="logo-container" :class="{ 'is-active': openMenu }" aria-label="homepage">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000.000000 1256.000000"> <g transform="translate(0.000000,1256.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"> <path d="M0 8062 c0 -4414 0 -4500 19 -4574 71 -276 299 -587 563 -765 57 -38
          897 -582 1868 -1207 971 -626 1809 -1168 1864 -1206 112 -77 269 -158 377
          -196 337 -115 635 -50 1044 230 66 45 930 604 1920 1240 990 637 1818 1170
          1840 1184 22 15 88 74 146 132 170 168 291 374 339 574 20 80 20 154 20 4584
          l0 4502 -5000 0 -5000 0 0 -4498z"></path> </g>
        </svg>
        <img class="logo" alt="Backfield Elite" src="../assets/images/logo-white.png"/>
      </router-link>
      <nav class="container site-header__nav">
        <ul>
          <li><router-link to="/#about-us" @click="scrollToHash('about-us')">About Us</router-link></li>
          <li><router-link to="/#our-features" @click="scrollToHash('our-features')">Features</router-link></li>
          <li><router-link to="/#specials" @click="scrollToHash('specials')">Specials</router-link></li>
        </ul>
        <ul>
          <li><router-link to="#signup" @click="scrollToHash('signup')">SignUp</router-link></li>
          <li><router-link to="/#blog-content" @click="scrollToHash('blog-content')">Blog</router-link></li>
          <li><router-link to="#contact" @click="scrollToHash('contact')">Contact</router-link></li>
        </ul>
      </nav>
      <div class="site-header__mobile">
        <button
          type="button"
          id="mobile-menu-opener"
          aria-label="Main menu"
          :class="{ 'is-active': openMenu }"
          @click="toggleMenu"
        >
          <i></i>
          <span class="screen-reader-text"></span>
        </button>
      </div>
    </div>
    <div v-if="openMenu" class="overlay" @click="toggleMenu"></div>
    <div :class="{ 'is-active': openMenu, 'sticky': scrolled }" class="open-menu">
      <ul>
        <li><router-link to="/#about-us" @click="scrollToHash('about-us')">About Us</router-link></li>
        <li><router-link to="/#our-features" @click="scrollToHash('our-features')">Features</router-link></li>
        <li><router-link to="/#specials" @click="scrollToHash('specials')">Specials</router-link></li>
        <li><router-link to="#signup" @click="scrollToHash('signup')">SignUp</router-link></li>
        <li><router-link to="/#blog-content" @click="scrollToHash('blog-content')">Blog</router-link></li>
        <li><router-link to="#contact" @click="scrollToHash('contact')">Contact</router-link></li>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  name: 'SiteHeader',

  data () {
    return {
      scrolled: false,
      openMenu: false
    }
  },

  mounted () {
    addEventListener('scroll', () => this.handleScroll())
  },

  methods: {
    handleScroll () {
      if (window.scrollY > 100) {
        this.scrolled = true
      } else {
        this.scrolled = false
      }
    },

    scrollToHash (hash) {
      setTimeout(() => {
        document.getElementById(hash).scrollIntoView()
      }, 200)
    },

    toggleMenu () {
      this.openMenu = !this.openMenu
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/components/header';
</style>
