<template>
  <section id="specials" class="be-badges">
    <div class="content container">
      <h2 class="title dark" v-text="'Specials'"/>
      <div class="divider"/>
      <h3 class="subtitle dark" v-text="'Unlock a world of exclusive badges.'"/>
      <div class="be-badges__text">
        <p class="text">
          Compete in games and tournaments to earn badges that lead to incredible rewards -
          official team kits, tickets to major matches and more. With various collections and unique levels of rarity,
          each badge showcases your journey and sets you apart.
        </p>
        <p class="text">
          Elevate your status, build your collection, and let your victories make you stand out!
        </p>
      </div>
      <div class="badges">
        <div class="be-badges__item">
          <img alt="" :src="require(`../assets/images/specials/jersey-three.png`)" />
          <p class="be-badges__title">Effort Master</p>
          <p class="text">The most hard working player of the team</p>
        </div>
        <div class="be-badges__item">
          <img alt="Elite Leader" :src="require(`../assets/images/specials/captain.png`)" />
          <p class="be-badges__title">Elite Leader</p>
          <p class="text">Some are innate leaders, some learn along the way. Both take the team to the victory</p>
        </div>
        <div class="be-badges__item">
          <img alt="Game changer" :src="require(`../assets/images/specials/10_green.png`)" />
          <p class="be-badges__title">Game changer</p>
          <p class="text">Key player on the field</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Badges'
}
</script>

<style lang="scss">
@import '../assets/styles/components/badges';
</style>
