<template>
  <div class="be-detail-page">
    <hero-banner
      id="home"
      mode="fifty"
      :content="blog"
      :show-breadcrumbs="true"
      :style="`background-image: linear-gradient(to bottom, rgba(0, 0, 0, .65), rgba(0, 0, 0, .65)), url(${mainImage});`"
    />
    <div class="container" v-html="blog.content"></div>
    <sign-up/>
  </div>
</template>

<script>
import HeroBanner from '@/components/HeroBanner.vue'
import SignUp from '@/components/SignUp.vue'

export default {
  name: 'BlogDetailView',

  components: { HeroBanner, SignUp },

  data () {
    return {
      blog: {
        date: '',
        title: '',
        content: '',
        image: ''
      }
    }
  },

  mounted () {
    this.getBlogDetail()
  },

  computed: {
    mainImage () {
      if (this.blog.image === '') {
        return ''
      }

      return JSON.parse(this.blog.image)[0] || ''
    }
  },

  methods: {
    async getBlogDetail () {
      const link = this.$route.params.id
      const response = await fetch(`https://application.backfieldelite.com/management/blog/get-single?link=${link}`)
      const data = await response.json()

      JSON.parse(data.image).forEach((image, index) => {
        data.content = data.content.replaceAll(`{{image${index + 1}}}`, `<img class="blog__image col-md-12" alt="blog" src="${image}"/>`)
      })

      this.blog = data
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/detailpage.scss';
</style>
