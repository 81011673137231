<template>
  <section id="signup" class="be-sign-up">
    <div class="content">
      <h2 class="title">
        Get in touch
      </h2>
      <div class="divider"/>
      <p class="text">
        Fill the form to get notification when app is launched.
      </p>
      <div class="be-sign-up__form">
        <form>
            <div class="form-group">
              <input
                v-model="email"
                type="text"
                id="email"
                name="email"
                placeholder="Email address"
                required
                :class="{ 'error': showError && email === '' }"
              >
              <button type="button" :disabled="buttonDisable" name="submit" id="submit" class="submit-button" @click="validateCaptcha()">Submit</button>
            </div>
            <p v-if="success" class="text success">
              Your message sent successfully!
            </p>
            <p v-if="error" class="text error">
              Something went wrong, please try again!
            </p>
          </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SignUp',

  data () {
    return {
      email: '',
      showError: false,
      success: false,
      error: false,
      buttonDisable: false
    }
  },

  methods: {
    async validateCaptcha () {
      try {
        if (!window.grecaptcha) {
          await new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = 'https://www.google.com/recaptcha/api.js?render=6LcGl0QqAAAAABTOPnkw66BTs3zF4HsTqSCJAdhj'
            script.onload = resolve
            document.head.appendChild(script)
          })
        }

        // Execute reCAPTCHA and get the token
        const token = await new Promise((resolve, reject) => {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LcGl0QqAAAAABTOPnkw66BTs3zF4HsTqSCJAdhj').then(resolve).catch(reject)
          })
        })

        if (token) {
          this.sendForm()
          return
        }

        this.showError = true
      } catch (error) {
        console.error('Error during CAPTCHA verification:', error)
      }
    },

    sendForm () {
      if (this.email === '') {
        this.showError = true

        return
      }

      this.showError = false
      this.buttonDisable = true
      const axios = require('axios')

      axios
        .post('https://application.backfieldelite.com/landing/email/send', { email: this.email })
        .then(() => { this.success = true })
        .catch((error) => {
          this.error = error
          this.buttonDisable = true
        })

      this.email = ''
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/components/sign-up';
</style>
