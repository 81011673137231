<template>
  <section id="contact" class="be-site-footer">
    <div class="content">
      <div class="header">
        <h2 class="title">
          LET'S DO THIS!
        </h2>
        <div class="divider"/>
        <p class="subtitle">
          Get in Touch!
        </p>
        <p class="text">
          If you have a task in mind, would like to work with us or just want to say hello, don't be shy. We'd love to meet you. Really!
        </p>
      </div>
      <div class="contact">
        <div class="contact__details">
          <div class="divider"></div>
          <h3>Contact Details</h3>
          <a href="mailto:info@backfieldelite.com"><span><i class="fa fa-envelope"></i>info@backfieldelite.com</span></a>
          <div class="divider"></div>
          <h3>Social Media</h3>
          <div class="social-media">
            <a target="_blank" aria-label="Twitter url" href="https://www.twitter.com/backfieldelite/"><span><i class="fa fa-twitter"></i></span></a>
            <a target="_blank" aria-label="Linkedin url" href="https://www.linkedin.com/company/backfieldelite"><span><i class="fa fa-linkedin"></i></span></a>
            <a target="_blank" aria-label="Instagram url" href="https://www.instagram.com/backfieldelite/"><span><i class="fa fa-instagram"></i></span></a>
          </div>
        </div>
        <div class="contact__form">
          <form>
            <div class="form-group">
              <input
                v-model="formData.name"
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                required
                :class="{ 'error': showError && formData.name === '' }"
              >
              <input
                v-model="formData.surname"
                type="text"
                id="last" name="last"
                placeholder="Last"
                required
                :class="{ 'error': showError && formData.surname === '' }"
              >
            </div>
            <input
              v-model="formData.email"
              type="email"
              id="email"
              name="email"
              placeholder="Email*"
              required
              :class="{ 'error': showError && formData.email === '' }"
            >
            <input v-model="formData.phone" type="phone" id="phone" name="phone" placeholder="Phone Number">
            <textarea
              v-model="formData.message"
              id="message"
              name="message"
              placeholder="Message*"
              required
              :class="{ 'error': showError && formData.message === '' }"
            ></textarea>
            <p v-if="success" class="text success">
              Your message sent successfully!
            </p>
            <p v-if="error" class="text error">
              Something went wrong, please try again!
            </p>
            <button type="button" :disabled="buttonDisable" name="submit" id="submit" class="submit-button" @click="validateCaptcha()">Submit</button>
          </form>
        </div>
      </div>
    </div>
    <div class="copyright container">
      <p>Copyright © 2022 | Backfield Elite.</p>
      <div class="footer__menu">
        <ul>
          <li><router-link to="/terms" aria-label="Terms">Terms</router-link ></li>
          <li><router-link to="/privacy-policy" aria-label="Privacy Policy">Privacy Policy</router-link ></li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SiteFooter',

  data () {
    return {
      formData: {
        name: '',
        surname: '',
        email: '',
        phone: '',
        message: '',
        type: 'mail'
      },
      showError: false,
      success: false,
      error: false,
      buttonDisable: false
    }
  },

  methods: {
    async validateCaptcha () {
      try {
        if (!window.grecaptcha) {
          await new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = 'https://www.google.com/recaptcha/api.js?render=6LcGl0QqAAAAABTOPnkw66BTs3zF4HsTqSCJAdhj'
            script.onload = resolve
            document.head.appendChild(script)
          })
        }

        // Execute reCAPTCHA and get the token
        const token = await new Promise((resolve, reject) => {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LcGl0QqAAAAABTOPnkw66BTs3zF4HsTqSCJAdhj').then(resolve).catch(reject)
          })
        })

        if (token) {
          this.sendForm()
          return
        }

        this.showError = true
      } catch (error) {
        console.error('Error during CAPTCHA verification:', error)
      }
    },

    sendForm () {
      if (this.formData.name === '' ||
        this.formData.surname === '' ||
        this.formData.email === '' ||
        this.formData.message === ''
      ) {
        this.showError = true

        return
      }

      this.showError = false
      this.buttonDisable = true
      const axios = require('axios')

      axios
        .post('https://application.backfieldelite.com/landing/email/send', this.formData)
        .then(() => { this.success = true })
        .catch((error) => {
          this.error = error
          this.buttonDisable = true
        })

      this.formData = {
        name: '',
        surname: '',
        email: '',
        phone: '',
        message: ''
      }
      // const recaptchaResponse = window.grecaptcha.getResponse()
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/components/site-footer';
</style>
